import React from 'react';
import LayoutComponent from '../components/LayoutComponent';

class NotFoundPage extends React.Component {
  render() {
    return (
      <LayoutComponent>
        <h1>Not Found</h1>
        <p>You just hit a page that doesn&#39;t exist...</p>
      </LayoutComponent>
    );
  }
}

export default NotFoundPage;
